import { useState } from "react";

import {
  Alert,
  Container,
  Header,
  SpaceBetween,
  Button,
  FormField,
} from "@amzn/awsui-components-react/polaris";

interface FileOption {
  value: string;
  label: string;
  lastModifiedTimestamp: number;
}
const MedicalAuthorizationDocuments = () => {
  const [selectedOption, setSelectedOption] = useState<FileOption | null>(null);

  return (
    <SpaceBetween direction="vertical" size="s">
      <Container
        header={
          <Header variant="h2">
            Authorization for Initial Medical Evaluation
          </Header>
        }
      >
        <SpaceBetween direction="vertical" size="s">
          <Alert header="TPA Change Warning" type="warning">
            Your state was identified as one of the states that switched the
            Workers&apos; Compensation Third Party Administrator from Sedgwick
            to Helmsman, as such, incidents that happened on or after 10/1/2024
            will require the Helmsman Authorization form and incidents that
            happened before 10/1/2024 will require the Sedgwick Authorization
            form
          </Alert>
          <SpaceBetween direction="vertical" size="l">
            {/* <Select
              options={fileOptions}
              selectedOption={selectedOption}
              onChange={({ detail }: { detail: SelectProps.ChangeDetail }) => {
                setSelectedOption(detail.selectedOption as FileOption);
              }}
              filteringType="auto"
              placeholder="Select a file to download"
              virtualScroll={true}
            ></Select> */}
            <FormField label="Helmsman Authorization for Initial Medical Evaluation.pdf">
              <Button
                variant="primary"
                onClick={() => {
                  return;
                }}
              >
                Download
              </Button>
            </FormField>
            <FormField label="Sedgwick Authorization for Initial Medical Evaluation.pdf">
              <Button
                variant="primary"
                onClick={() => {
                  return;
                }}
              >
                Download
              </Button>
            </FormField>
            {/* <Box variant="strong">
              
            </Box> */}
          </SpaceBetween>
        </SpaceBetween>
      </Container>
    </SpaceBetween>
  );
};
export default MedicalAuthorizationDocuments;
