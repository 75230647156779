export const stateFormOptions = [
  {
    value: "stateForm.pdf",
    label: "Alabama Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "Alaska Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "Arizona Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "Arkansas Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "colora.pdf",
    label: "California Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "Colorado Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "Delaware Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "Florida Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "Idaho Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "Illinois Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "Indiana Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "Kansas Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "Kentucky Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "Maine Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "Maryland Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "Massachusetts Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "Michigan Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "Minnesota Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "Mississipi Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "Montana Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "Nebraska Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "Nevada Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "New Jersey Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "New Mexico Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "New York Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "North Carolina Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "North Dakota Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "Ohio Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "Oklahoma Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "Oregon Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "Pennsylvania Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "Rhode Island Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "South Carolina Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "South Dakota Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "Tennessee Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "Texas Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "Utah Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "Vermont Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "Virginia Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "Washington Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "West Virginia Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "Wisconsin Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
  {
    value: "stateForm.pdf",
    label: "Wyoming Workers' Compensation State Form.pdf",
    lastModifiedTimestamp: 23452345,
  },
];
