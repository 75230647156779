import { useState } from "react";

import {
  Alert,
  Container,
  Header,
  SpaceBetween,
  Button,
  FormField,
  Box,
  Icon,
  Link,
  Spinner,
  Select,
  SelectProps,
  Grid,
} from "@amzn/awsui-components-react/polaris";
import { stateFormOptions } from "./stateFormMockOptions";

interface FileOption {
  value: string;
  label: string;
  lastModifiedTimestamp: number;
}

const StateForms = () => {
  const [selectedOption, setSelectedOption] = useState<FileOption | null>(null);

  return (
    <SpaceBetween direction="vertical" size="s">
      <Container header={<Header variant="h2">State Forms</Header>}>
        <SpaceBetween direction="vertical" size="s">
          <SpaceBetween size="xs" direction="vertical">
            <Box
              variant="p"
              color="text-status-success"
              data-testid="matchingFileSuccess"
            >
              <Icon name="status-positive"></Icon> Click on the download link
              below to get the Workers&apos; Compensation state form for your
              state or search for another state&apos;s Workers&apos;
              Compensation form in the dropdown menu.
            </Box>
            <Box variant="p">
              <b>File: </b>
              <span
                data-testid="matchingFileDownloadLink"
                onClick={() => {
                  return;
                }}
              >
                <Link variant="primary">
                  California Workers&apos; Compensation State Form{" "}
                  <Icon name="download"></Icon>
                </Link>
              </span>

              <br />
            </Box>

            <FormField label="Select another workers' compensation state form">
              <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
                <Select
                  options={stateFormOptions}
                  selectedOption={selectedOption}
                  onChange={({
                    detail,
                  }: {
                    detail: SelectProps.ChangeDetail;
                  }) => {
                    setSelectedOption(detail.selectedOption as FileOption);
                  }}
                  filteringType="auto"
                  placeholder="Select a file to download"
                  virtualScroll={true}
                ></Select>
                <Button
                  disabled={!selectedOption}
                  variant="primary"
                  onClick={() => {
                    return;
                  }}
                >
                  Download
                </Button>
              </Grid>
            </FormField>
          </SpaceBetween>
        </SpaceBetween>
      </Container>
    </SpaceBetween>
  );
};
export default StateForms;
