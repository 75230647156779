import React, { useState } from "react";

import {
  Alert,
  Container,
  Header,
  SpaceBetween,
  Button,
  FormField,
  ContentLayout,
  SpaceBetweenProps,
  Grid,
  Select,
  SelectProps,
  FileUpload,
  FileUploadProps,
  Modal,
  Box,
  StatusIndicator,
} from "@amzn/awsui-components-react/polaris";
import { stateFormOptions } from "src/components/stateForms/stateFormMockOptions";
import { response } from "src/medicalPanelData/mockResponse";

interface FileOption {
  value: string;
  label: string;
  lastModifiedTimestamp: number;
}
const DocumentsManagement = () => {
  const [selectedMedicalPanel, setSelectedMedicalPanel] =
    useState<FileOption | null>(null);

  const [selectedAuthorizationForm, setSelectedAuthorizationForm] =
    useState<FileOption | null>(null);

  const [selectedStateForm, setSelectedStateForm] = useState<FileOption | null>(
    null
  );

  const [uploadedMedicalPanels, setUploadedMedicalPanels] = useState<any>([]);
  const [uploadedAuthorizationForms, setUploadedAuthorizationForms] =
    useState<any>([]);

  const [uploadedStateForms, setUploadedStateForms] = useState<any>([]);
  const [visible, setVisible] = useState(false);

  const medicalPanelOptions = response.files.map((item) => {
    return {
      value: item.fileName,
      label: item.fileName,
      lastModifiedTimestamp: item.lastModifiedTimestamp,
    };
  });

  const authorizationForms = [
    {
      value: "Sedgwick Authorization Form.pdf",
      label: "Sedgwick Authorization Form.pdf",
    },

    {
      value: "Helmsman Authorization Form.pdf",
      label: "Helmsman Authorization Form.pdf",
    },
    {
      value: "Anchor Authorization Form.pdf",
      label: "Anchor AuthorizationForm.pdf",
    },
  ];
  return (
    <ContentLayout maxContentWidth={1000}>
      <Modal
        onDismiss={() => setVisible(false)}
        visible={visible}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link">Cancel</Button>
              <Button variant="primary">Upload</Button>
            </SpaceBetween>
          </Box>
        }
        header="Duplicate File Name Found"
      >
        One or more of the files that you have selected for upload have the same
        file name as a file on the current list, are you sure you want to upload
        these files? Files with the same file name will be replaced.
      </Modal>
      <SpaceBetween size="s" direction="vertical">
        <Container
          header={<Header variant="h2">Medical Provider Panels</Header>}
        >
          <SpaceBetween size="m" direction="vertical">
            <FormField
              label="Select from the dropdown to download or delete a medical panel file"
              stretch
            >
              <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                <Select
                  options={medicalPanelOptions}
                  selectedOption={selectedMedicalPanel}
                  onChange={({
                    detail,
                  }: {
                    detail: SelectProps.ChangeDetail;
                  }) => {
                    setSelectedMedicalPanel(
                      detail.selectedOption as FileOption
                    );
                  }}
                  filteringType="auto"
                  placeholder="Select a file to download"
                  virtualScroll={true}
                ></Select>
                {selectedMedicalPanel && (
                  <SpaceBetween direction="horizontal" size="xxs">
                    <Button
                      disabled={!selectedMedicalPanel}
                      onClick={() => {
                        return;
                      }}
                    >
                      Download
                    </Button>
                    <Button
                      disabled={!selectedMedicalPanel}
                      onClick={() => {
                        return;
                      }}
                      variant="link"
                    >
                      <StatusIndicator type="error">Delete</StatusIndicator>
                    </Button>
                  </SpaceBetween>
                )}
              </Grid>
            </FormField>
            <FormField label="Upload Medical Panel Files">
              <FileUpload
                onChange={({
                  detail,
                }: {
                  detail: FileUploadProps.ChangeDetail;
                }) => setUploadedMedicalPanels(detail.value)}
                value={uploadedMedicalPanels}
                i18nStrings={{
                  uploadButtonText: (e: any) =>
                    e ? "Choose files" : "Choose file",
                  dropzoneText: (e: any) =>
                    e ? "Drop files to upload" : "Drop file to upload",
                  removeFileAriaLabel: (e: any) => `Remove file ${e + 1}`,
                  limitShowFewer: "Show fewer files",
                  limitShowMore: "Show more files",
                  errorIconAriaLabel: "Error",
                }}
                multiple={true}
                showFileLastModified
                showFileSize
                tokenLimit={100}
              />
            </FormField>
            <Button
              disabled={!uploadedMedicalPanels.length}
              variant="primary"
              onClick={() => {
                setVisible(true);
              }}
            >
              Upload
            </Button>
          </SpaceBetween>
        </Container>

        <Container
          header={
            <Header variant="h2">
              Authorization for Initial Medical Evaluation
            </Header>
          }
        >
          <SpaceBetween size="m" direction="vertical">
            <FormField
              label="Select from the dropdown to download or delete an authorization form"
              stretch
            >
              <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                <Select
                  options={authorizationForms}
                  selectedOption={selectedAuthorizationForm}
                  onChange={({
                    detail,
                  }: {
                    detail: SelectProps.ChangeDetail;
                  }) => {
                    setSelectedAuthorizationForm(
                      detail.selectedOption as FileOption
                    );
                  }}
                  filteringType="auto"
                  placeholder="Select a file to download"
                  virtualScroll={true}
                ></Select>
                {selectedAuthorizationForm && (
                  <SpaceBetween direction="horizontal" size="xxs">
                    <Button
                      disabled={!selectedAuthorizationForm}
                      onClick={() => {
                        return;
                      }}
                    >
                      Download
                    </Button>
                    <Button
                      disabled={!selectedAuthorizationForm}
                      onClick={() => {
                        return;
                      }}
                      variant="link"
                    >
                      <StatusIndicator type="error">Delete</StatusIndicator>
                    </Button>
                  </SpaceBetween>
                )}
              </Grid>
            </FormField>
            <FormField label="Upload Authorization forms">
              <FileUpload
                onChange={({
                  detail,
                }: {
                  detail: FileUploadProps.ChangeDetail;
                }) => setUploadedAuthorizationForms(detail.value)}
                value={uploadedAuthorizationForms}
                i18nStrings={{
                  uploadButtonText: (e: any) =>
                    e ? "Choose files" : "Choose file",
                  dropzoneText: (e: any) =>
                    e ? "Drop files to upload" : "Drop file to upload",
                  removeFileAriaLabel: (e: any) => `Remove file ${e + 1}`,
                  limitShowFewer: "Show fewer files",
                  limitShowMore: "Show more files",
                  errorIconAriaLabel: "Error",
                }}
                multiple={true}
                showFileLastModified
                showFileSize
                tokenLimit={100}
              />
            </FormField>
            <Button
              disabled={!uploadedAuthorizationForms.length}
              variant="primary"
              onClick={() => {
                setVisible(true);
              }}
            >
              Upload
            </Button>
          </SpaceBetween>
        </Container>

        <Container header={<Header variant="h2">State Forms</Header>}>
          <SpaceBetween size="m" direction="vertical">
            <FormField
              label="Select from the dropdown to download or delete a state form"
              stretch
            >
              <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                <Select
                  options={stateFormOptions}
                  selectedOption={selectedStateForm}
                  onChange={({
                    detail,
                  }: {
                    detail: SelectProps.ChangeDetail;
                  }) => {
                    setSelectedStateForm(detail.selectedOption as FileOption);
                  }}
                  filteringType="auto"
                  placeholder="Select a file to download"
                  virtualScroll={true}
                ></Select>
                <Box>
                  {selectedStateForm && (
                    <SpaceBetween direction="horizontal" size="xxs">
                      <Button
                        disabled={!selectedStateForm}
                        onClick={() => {
                          return;
                        }}
                      >
                        Download
                      </Button>
                      <Button
                        disabled={!selectedStateForm}
                        onClick={() => {
                          return;
                        }}
                        variant="link"
                      >
                        <StatusIndicator type="error">Delete</StatusIndicator>
                      </Button>
                    </SpaceBetween>
                  )}
                </Box>
              </Grid>
            </FormField>
            <FormField label="Upload Authorization forms">
              <FileUpload
                onChange={({
                  detail,
                }: {
                  detail: FileUploadProps.ChangeDetail;
                }) => setUploadedStateForms(detail.value)}
                value={uploadedStateForms}
                i18nStrings={{
                  uploadButtonText: (e: any) =>
                    e ? "Choose files" : "Choose file",
                  dropzoneText: (e: any) =>
                    e ? "Drop files to upload" : "Drop file to upload",
                  removeFileAriaLabel: (e: any) => `Remove file ${e + 1}`,
                  limitShowFewer: "Show fewer files",
                  limitShowMore: "Show more files",
                  errorIconAriaLabel: "Error",
                }}
                multiple={true}
                showFileLastModified
                showFileSize
                tokenLimit={100}
              />
            </FormField>
            <Button
              disabled={!uploadedStateForms.length}
              variant="primary"
              onClick={() => {
                setVisible(true);
              }}
            >
              Upload
            </Button>
          </SpaceBetween>
        </Container>
      </SpaceBetween>
    </ContentLayout>
  );
};
export default DocumentsManagement;
