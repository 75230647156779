import { Box } from "@amzn/awsui-components-react/polaris";
import React from "react";
import { PAGE_TITLES, ROUTES } from "src/app/constants";
import { useLocation } from "react-router";
import NavigationBarActions from "./NavigationBarActions";
import ClaimsSearchBar from "./ClaimsSearchBar";
import { useAppSelector } from "src/redux/hooks";
import { RootState } from "src/redux/store";

const ROUTE_TO_TITLE_MAP: { [key: string]: string } = {
  [ROUTES.CLAIM_INTAKE_FORM]: PAGE_TITLES.CLAIM_INTAKE_FORM,
  [ROUTES.MEDICAL_PANEL_DATA]: PAGE_TITLES.MEDICAL_PANEL_DATA,
  [ROUTES.SITE_CONFIGURATION]: PAGE_TITLES.SITE_CONFIGURATION,
  [ROUTES.CLAIMS_HISTORY]: PAGE_TITLES.CLAIMS_HISTORY,
  [ROUTES.WORKERS_COMPENSATION_DOCUMENTS]:
    PAGE_TITLES.WORKERS_COMPENSATION_DOCUMENTS,
};
const TopNavigationBar = () => {
  const { showSmallScreenSearchBar } = useAppSelector(
    (state: RootState) => state.app
  );

  const location = useLocation();
  const title =
    ROUTE_TO_TITLE_MAP[location.pathname || ""] ||
    PAGE_TITLES.CLAIM_INTAKE_FORM;
  const isClaimIntakeFormPage = location.pathname === ROUTES.CLAIM_INTAKE_FORM;
  return (
    <React.Fragment>
      <header className="app-header" id="app-header">
        <div className="title-and-search-bar">
          {showSmallScreenSearchBar ? null : (
            <div className="page-title">
              <Box padding={{ top: "xxs", left: "l" }}>
                <span className="header-text">{title}</span>
              </Box>
            </div>
          )}
          {isClaimIntakeFormPage ? (
            <ClaimsSearchBar topNavigation={true} />
          ) : null}
        </div>
        {showSmallScreenSearchBar ? null : <NavigationBarActions />}
      </header>
    </React.Fragment>
  );
};
export default TopNavigationBar;
