import React from "react";

import {
  ContentLayout,
  SpaceBetween,
} from "@amzn/awsui-components-react/polaris";

import MedicalPanelData from "src/medicalPanelData/MedicalPanelData";
import MedicalAuthorizationDocuments from "src/components/medicalAuthorizationDocuments/MedicalAuthorizationDocuments";
import StateForms from "src/components/stateForms/StateForms";

const WorkersCompensationDocuments = () => {
  return (
    <ContentLayout>
      <SpaceBetween direction="vertical" size="m">
        <MedicalPanelData />
        <MedicalAuthorizationDocuments />
        <StateForms />
      </SpaceBetween>
    </ContentLayout>
  );
};
export default WorkersCompensationDocuments;
